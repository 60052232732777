<template lang="pug">
  div.card.monitor-count-card
    div.card-body
      div.btn-reload
        button.btn.btn-link(type="button", @click="get")
          span(data-feather="refresh-cw")
      div.container-fluid
        div.row
          div.col
            h6.card-title
              span(data-feather="layout")
              | 監視サイト
        div.row
          div.col.position-relative
            div.position-absolute.top-50.start-50.translate-middle(v-if="loading")
              div.spinner-border
            div.position-absolute.start-50.translate-middle(v-else, style="top:55%;")
              h2.monitor {{ monitor_count.total }} 
                span.inactive ({{ monitor_count.inactive.total }})
          div.col-auto
            dl
              dt 1分
              dd {{ monitor_count._1min }}&nbsp;
                span.inactive ({{ monitor_count.inactive._1min }})
              dt 10分
              dd {{ monitor_count._10min }}&nbsp;
                span.inactive ({{ monitor_count.inactive._10min }})
              dt 1時間
              dd {{ monitor_count._60min }}&nbsp;
                span.inactive ({{ monitor_count.inactive._60min }})
</template>

<script>
import feather from 'feather-icons';

export default {
  name:"MonitorCountCard",
  data (){
    return {
      loading:true,
      // monitor_count:{
      //   loaded:false,
      //   total:"-",
      //   _1min:"-",
      //   _10min:"-",
      //   _60min:"-",
      //   inactive:{
      //     total:"-",
      //     _1min:"-",
      //     _10min:"-",
      //     _60min:"-",
      //   }
      // }
    }
  },
  computed: {
    monitor_count (){ return this.$store.state.monitor_count; }
  },
  mounted (){
    feather.replace();
    if (this.$store.state.monitor_count.loaded)
      this.loading = false;
    else {
      this.get();
    }
  },
  methods: {
    get (){
      this.loading = true;
      this.axios.get("/api/monitorcount")
      .then((response)=>{
        this.$store.commit("monitor_count", response.data);
        this.loading = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.card.monitor-count-card {
  width:250px;
  height:150px;

  .feather {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .btn-reload {
    position: absolute;
    top:0;
    right:0;
    button {
      padding: 8px;
    }
    .feather {
      color: rgba(0,0,0,.3);
      margin-right: 0;
    }
  }
}


.inactive {
  color: rgba(0,0,0,.5);
}

.monitor {
  line-height: 1.5rem;
  .inactive {
    font-size: 1rem;
  }
}

dt {
  float: left ;
  clear: left ;
  margin-right: 0.5em ;
  width: 50px ;
}

dd {
  float: left ;
  margin-left: 1em ;
}
</style>