<template lang="pug">
  div.card.sales-card
    div.card-body
      div.btn-reload
        button.btn.btn-link(type="button", @click="get")
          span(data-feather="refresh-cw")
      div.container-fluid
        div.row
          div.col
            h6.card-title
              span(data-feather="dollar-sign")
              | 売上
        div.row(v-if="loading")
          div.col.text-center.mt-4
            div.spinner-border
        div.row.text-center.mt-3(v-else)
          div.col-12
            p.mb-1 {{ sales.year }}年{{ sales.month }}月
          div.col-12
            h4 ￥{{ sales.sales }}
</template>

<script>
import feather from 'feather-icons';

export default {
  name:"SalesCard",
  data (){
    return {
      loading:true,
      // sales:{
      //   loaded:false,
      //   year:"-",
      //   month:"-",
      //   sales:"-",
      // }
    }
  },
  computed:{
    sales (){ return this.$store.state.sales; }
  },
  mounted (){
    feather.replace();
    if (this.$store.state.user_count.loaded)
      this.loading = false;
    else {
      this.get();
    }  },
  methods: {
    get (){
      this.loading = true;
      this.axios.get("/api/sales")
      .then((response)=>{
        this.$store.commit("sales", response.data);
        this.loading = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.card.sales-card {
  width:250px;
  height:150px;

  .feather {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .btn-reload {
    position: absolute;
    top:0;
    right:0;
    button {
      padding: 8px;
    }
    .feather {
      color: rgba(0,0,0,.3);
      margin-right: 0;
    }
  }
}

dt {
  float: left ;
  clear: left ;
  margin-right: 0.5em ;
  width: 60px ;
}

dd {
  float: left ;
  margin-left: 1em ;
}
</style>