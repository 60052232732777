import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueSwal from 'vue-swal'
import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/default.css' // or other highlight.js theme
import VueHead from 'vue-head'

// Tell Vue.js to use vue-highlightjs
Vue.use(VueHighlightJS)

Vue.use(VueHead)
Vue.use(VueSwal)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.NeedAuth) {
    if(store.state.signin)
      next();
    else
      next({ path: '/signin', query:{ next:to.fullPath } });
  }
  else if (to.path == '/signin' && store.state.signed_in)
    next({ path: '/' });
  else
    next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
