<template lang="pug">
  div.card.user-count-card
    div.card-body
      div.btn-reload
        button.btn.btn-link(type="button", @click="get")
          span(data-feather="refresh-cw")
      div.container-fluid
        div.row
          div.col
            h6.card-title
              span(data-feather="user")
              | 登録ユーザー
        div.row
          div.col.position-relative
            div.position-absolute.top-50.start-50.translate-middle
              div.spinner-border(v-if="loading")
              h2(v-else) {{ user_count.total }}
          div.col-auto
            dl
              dt Trial
              dd {{ user_count.trial }}
              dt Monthly
              dd {{ user_count.monthly }}
              dt Yearly
              dd {{ user_count.yearly }}
</template>

<script>
import feather from 'feather-icons';

export default {
  name:"UserCountCard",
  data (){
    return {
      loading:true,
      // user_count:{
      //   loaded:false,
      //   total:"-",
      //   trial:"-",
      //   monthly:"-",
      //   yearly:"-"
      // }
    }
  },
  computed:{
    user_count (){ return this.$store.state.user_count; },
  },
  mounted (){
    feather.replace();
    if (this.$store.state.user_count.loaded)
      this.loading = false;
    else {
      this.get();
    }
  },
  methods: {
    get (){
      this.loading = true;
      this.axios.get("/api/usercount")
      .then((response)=>{
        this.$store.commit("user_count", response.data);
        this.loading = false;
      });

    }
  }
}
</script>

<style lang="scss" scoped>
.card.user-count-card {
  width:250px;
  height:150px;

  .feather {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .btn-reload {
    position: absolute;
    top:0;
    right:0;
    button {
      padding: 8px;
    }
    .feather {
      color: rgba(0,0,0,.3);
      margin-right: 0;
    }
  }
}


dt {
  float: left ;
  clear: left ;
  margin-right: 0.5em ;
  width: 60px ;
}

dd {
  float: left ;
  margin-left: 1em ;
}
</style>