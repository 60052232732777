<template lang="pug">
    header.navbar.navbar-dark.sticky-top.bg-dark.flex-md-nowrap.p-0.shadow
      a.navbar-brand.col-md-3.col-lg-2.me-0.px-3(href="/")
        img(src="../assets/nav-logo.svg", height="32")
      button.ticket-btn.btn.d-md-none(type="button", @click="$parent.show_ticket_modal")
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-sticky feather" viewBox="0 0 16 16"><path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z"/></svg>
      button.navbar-toggler.position-absolute.d-md-none.collapsed(v-if="signin", type="button", data-bs-toggle="collapse", data-bs-target="#sidebarMenu", aria-controls="sidebarMenu", aria-expanded="false", aria-label="Toggle navigation")
        span.navbar-toggler-icon
</template>

<script>
export default {
  computed: {
    signin (){
      return this.$store.state.signin;
    }
  }
}
</script>

<style scoped>
.navbar-brand {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.navbar .ticket-btn {
  position: absolute;
  top: .25rem;
  right: 80px;
  color: rgba(255,255,255,.55);
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid rgba(255,255,255,.1);
  border-radius: 0.25rem;
  transition: box-shadow .15s ease-in-out;
  height: 40px;
  width: 56px;
}

</style>